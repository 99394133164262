import './App.css'
import React from 'react'
import Navbar from './sections/Navbar'
import ProductMenu from './sections/Product Section/ProductMenu'
import { CartProvider } from './sections/Cart Section/CartProvider'
import Location from './sections/RestaurantLocation'
import GetLocation from './sections/GetLocation'
import GoogleReviews from './sections/Review Section/GoogleReviews'
import Footer from './sections/Footer'
import { Helmet } from 'react-helmet'

export default function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Pilav Şöleni Küçükyalı</title>
      </Helmet>
      
      <Navbar />
      <Location />
      <CartProvider>
        <ProductMenu />
        <GetLocation />
      </CartProvider>
      <GoogleReviews />
      <Footer />
    </div>
  )
}
