import UserCartIcon from "./CartIcon"
import InsideCart from "./InsideCart"
import { useContext, useEffect } from 'react'
import { CartContext } from '../Cart Section/CartProvider'

export default function Cart({ isDeliverable, userLocationOn, userAddress }) {
    const { cartToggle, showIcon, showCloseButton, toggleCart, cartProduct } = useContext(CartContext)
    
    const totalQuantity = cartProduct.length

    useEffect(() => {
        function handleResize() {
            if (cartToggle && window.innerWidth <= 768) {
                document.body.classList.add('no-scroll')
                const navbar = document.querySelector('.navbar');
                if (navbar) {
                    navbar.style.zIndex = '0';
                }
            } else {
                document.body.classList.remove('no-scroll')
                const navbar = document.querySelector('.navbar');
                if (navbar) {
                    navbar.style.zIndex = '1';
                }
            }
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
            document.body.classList.remove('no-scroll')
            const navbar = document.querySelector('.navbar');
            if (navbar) {
                navbar.style.zIndex = '1';
            }
        }
    }, [cartToggle]) 

    return (
        <div className={`cart-section ${totalQuantity < 1 ? 'empty' : ''} ${cartToggle ? 'open' : ''}`}>
            <div className="cart-icon">
                <UserCartIcon toggleCart={toggleCart} showIcon={showIcon} />
                {showCloseButton && (
                    <button className="close-cart" onClick={toggleCart}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                )}
            </div>
            {cartToggle && (
                <InsideCart isDeliverable={isDeliverable} userLocationOn={userLocationOn} userAddress={userAddress} />
            )}
        </div>
    )
}
