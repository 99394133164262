import nohutluPilav from '../../images/tumbnail/nohutlu-pilav1727440296.jpg'
import pilavUstuTavukBir from '../../images/tumbnail/tavuklu-pilav-bir1727440298.jpg'
import pilavUstuTavukBirBucuk from '../../images/tumbnail/tavuklu-pilav-bir-buçuk1727440298.jpg'
import pilavUstuTavukluNohutluSalata from '../../images/tumbnail/tavuklu-nohutlu-pilav-üstü-salata1727440298.jpg'
import karısıkTavuklu from '../../images/tumbnail/karışık-tavuklu-pilav1727440295.jpg'
import pilavUstuKuru from '../../images/tumbnail/pilav-üstü-kuru-bir1727440296.jpg'
import pilavUstuTavukluKuru from '../../images/tumbnail/tavuklu-kurufasulyeli-pilav1727440298.jpg'
import porsiyonKuru from '../../images/tumbnail/kuru-prosiyon1727440296.jpg'
import pilavUstuKuruMısır from '../../images/tumbnail/kurufasulye-menu1727440295.jpg'
import pilavMenuBir from '../../images/tumbnail/kuru-pilav-menü1727440296.jpg'
import sporcuMenu from '../../images/tumbnail/sporcu-menu1727440297.jpg'
import pilavUstuSote from '../../images/tumbnail/pilav-ustu-tavuk-sote1727441528.jpg'
import mixMenu from '../../images/newProducts/mixMenu.jpg'
import pilavMix from '../../images/newProducts/pilavMix.jpg'
import solenMenu from '../../images/newProducts/SolenMenu.jpg'
import cobanSalata from '../../images/tumbnail/oban-salata1727440295.jpg'
import acıkAyran from '../../images/tumbnail/Açık Ayran (27,5 cl.)1727440294.jpg'
import beypazari from '../../images/tumbnail/Beypazarı Soda1727440294.jpg'
import cocaCola33cl from '../../images/tumbnail/Coca-Cola (33 cl.)1727440294.jpg'
import cocaColaZero33cl from '../../images/tumbnail/Coca-Cola Zero Sugar (33 cl.)1727440294.jpg'
import doganaySalgam from '../../images/tumbnail/Doğanay-Şalgam-Suyu-Acısız1727440294.jpg'
import sutasAyran30cl from '../../images/tumbnail/Sütaş Ayran (30 cl.)1727440297.jpg'

const Products = [
  {
    imageUrl: nohutluPilav,
    title: 'Nohutlu Pilav',
    description: '300gr. nohut, pirinç pilavı',
    price: 85,
    quantity: 0,
    barcode: '1',
  },
  {
    imageUrl: pilavUstuTavukBir,
    title: 'Tavuklu Nohutlu Pilav',
    description: '350 gr. nohutlu pilav, 50 gr. tavuk',
    price: 100,
    quantity: 0,
    barcode: '2',
  },
  {
    imageUrl: pilavUstuTavukBirBucuk,
    title: 'Tavuklu Pilav (1,5)',
    description: '400 gr. pilav, 75 gr. tavuk',
    price: 140,
    quantity: 0,
    barcode: '3',
  },
  {
    imageUrl: pilavUstuTavukluNohutluSalata,
    title: 'Tavuklu Pilav Üzeri Salata',
    description: 'Kurufasulye, Nohut, Tereyağı, Pirinç Pilavı',
    price: 140,
    quantity: 0,
    barcode: '4',
  },
  {
    imageUrl: karısıkTavuklu,
    title: 'Karışık Tavuklu Pilav',
    description: 'Tavuklu pilav, salata, mısır',
    price: 185,
    quantity: 0,
    barcode: '5',
  },
  {
    imageUrl: porsiyonKuru,
    title: 'Kuru Porsiyon',
    description: 'Kuru Fasulye',
    price: 135,
    quantity: 0,
    barcode: '6',
  },
  {
    imageUrl: pilavUstuKuru,
    title: 'Pilav Üstü Kuru Fasulye',
    description: '350 gr. pilav, 200 gr. kuru fasulye',
    price: 110,
    quantity: 0,
    barcode: '7',
  },
  {
    imageUrl: pilavUstuTavukluKuru,
    title: 'Tavuklu Kurulu Pilav',
    description: 'Tereyağlı pirinç pilavı üzerine kuru fasulye',
    price: 140,
    quantity: 0,
    barcode: '8',
  },
  {
    imageUrl: pilavUstuKuruMısır,
    title: 'Kuru/Mısır Sever Menü',
    description: 'Tereyağlı Pilav üstüne yarısı kuru fasülye yarısı mısır eklenerek oluşturulan bir menüdür',
    price: 175,
    quantity: 0,
    barcode: '9',
  },
  {
    imageUrl: pilavUstuSote,
    title: 'Pilav Üstü Tavuk Sote',
    description: 'Tereyağlı Pilav, Tavuk Sote',
    price: 145,
    quantity: 0,
    barcode: '10',
  },
  {
    imageUrl: pilavMenuBir,
    title: 'Ekonomik Menü',
    description: 'Tereyağlı kuru fasulye, tereyağlı pilav, kapalı ayran',
    price: 190,
    quantity: 0,
    barcode: '11',
  },
  {
    imageUrl: sporcuMenu,
    title: 'Sporcu Menü',
    description: 'Bol Tavuklu Pilav (1,5 Porsiyon) + Salata + Sütaş Ayran (30 cl.)',
    price: 200,
    quantity: 0,
    barcode: '12',
  },
  {
    imageUrl: mixMenu,
    title: 'Mix Menü',
    description: 'Tavuk Sote + Pirinç Pilavı + Kuru + Coca-Cola (33 cl.)',
    price: 220,
    quantity: 0,
    barcode: '13',
  },
  {
    imageUrl: pilavMix,
    title: 'Mix Pilav',
    description: 'Tavuk Sote + Pirinç Pilavı + Kuru',
    price: 200,
    quantity: 0,
    barcode: '14',
  },
  {
    imageUrl: solenMenu,
    title: 'Şölen Menü',
    description: 'Tavuk Sote + Pirinç Pilavı + Sütaş Ayran (30 cl.)',
    price: 220,
    quantity: 0,
    barcode: '15',
  },
  {
    imageUrl: cobanSalata,
    title: 'Çoban Salata',
    description: 'Domates, salatalık, zeytinyağı, limon suyu',
    price: 70,
    quantity: 0,
    barcode: '16',
  },
  {
    imageUrl: doganaySalgam,
    title: 'Doğanay Şalgam Suyu Acısız (30 cl.)',
    price: 35,
    quantity: 0,
    barcode: '17',
  },
  {
    imageUrl: beypazari,
    title: 'Beypazarı Soda (20 cl.)',
    price: 25,
    quantity: 0,
    barcode: '18',
  },
  {
    imageUrl: cocaCola33cl,
    title: 'Coca-Cola (33 cl.)',
    price: 45,
    quantity: 0,
    barcode: '19',
  },
  {
    imageUrl: cocaColaZero33cl,
    title: 'Coca-Cola Zero Sugar (33 cl.)',
    price: 45,
    quantity: 0,
    barcode: '20',
  },
  {
    imageUrl: acıkAyran,
    title: 'Açık Ayran (33 cl.)',
    price: 35,
    quantity: 0,
    barcode: '21',
  },
  {
    imageUrl: sutasAyran30cl,
    title: 'Sütaş Ayran (30 cl.)',
    price: 35,
    quantity: 0,
    barcode: '22',
  },
]

export default Products
