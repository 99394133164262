import React, { useEffect } from 'react'
import googlePlaces from './GooglePlaces'
import googleIcon from '../../images/icons/Google Icon.png'

export default function GoogleReviews() {
  useEffect(() => {
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    function loadGoogleApi() {
      sleep(1500).then(r => {
            const script = document.createElement('script')
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD_HXna6RdG2QZbUgrCCmMGxnId91MT8Xo&libraries=places`
            script.async = true
            script.defer = true
            script.onload = () => {
              if (window.google) {
                googlePlaces(window.google, 'google-reviews', {
                  placeId: 'ChIJ_6sxMZfHyhQRUW2ybTQJR3o',
                  showRecentOnly: true,
                })
              } else {
                console.error('Google API failed to load.')
              }
            }
            document.body.appendChild(script)
        }
      );

    }
  
    loadGoogleApi()
  }, [])
  

  function reviewButton() {
    window.open('https://www.google.com/search?hl=en-TR&gl=tr&q=K%C3%BC%C3%A7%C3%BCkyal%C4%B1,+Pilav+%C5%9E%C3%B6leni,+Cinar+Mh,+Kadir+Has+Cd.+No:45/6,+34840+Maltepe/%C4%B0stanbul&ludocid=8811021316757024081&lsig=AB86z5XEZEqcdaGzWdmR476cpV_c#lrd=0x14cac7973131abff:0x7a4709346db26d51,3')
  }

  return (
    <>
      <h1 id='google-comments'>Google Yorumlarımız</h1>
      <div id="google-reviews"></div>
      <button onClick={reviewButton} className='review-button'>
        <img className='google-icon' src={googleIcon} alt="Google Icon" />
        Bizi Google üzerinden değerlendirin</button>
    </>
  )
}
