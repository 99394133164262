import React from 'react'
import { GoogleMap, Circle, useLoadScript, Marker } from '@react-google-maps/api'

export default function Location() {
    const mapContainerStyle = {
        width: '100vw',
        height: '600px',
    }

    const center = {
        lat: 40.945333089189695, 
        lng: 29.119637725783186
    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyD_HXna6RdG2QZbUgrCCmMGxnId91MT8Xo",
    })

    if (loadError) {
        return <div>Error loading the map </div>
    }

    if (!isLoaded) {    
        return <div>Loading...</div>
    }

    return (
        <div className='kucukyali-pilavsoleni' id='map-section'>
            <div className='location-header-wrapper'>
                <h1 id="location-title">Küçükyalı, Pilav Şöleni</h1>
                <p>Pilav Şöleni, yalnızca Küçükyalı ve Maltepe Bölgesinde Hizmet Vermektedir.</p>
                <p>(Pilav Şöleni'nin başka bir şubesi bulunmamaktadır. Yalnızca Küçükyalı şubemiz bulunmaktadır.)</p>
            </div>
                    <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={15}
                    options={{
                        draggable: true, 
                    }}
                >
                    <Circle
                        center={center}
                        radius={1500}
                        options={{
                            strokeColor: "#FF0000",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "#FF0000",
                            fillOpacity: 0.10,
                        }}
                    />
                    <Marker
                        position={center}
                    />
                </GoogleMap>
        </div>
    )
}
