import React, { useState, useEffect, useCallback } from "react"
import { GoogleMap, useLoadScript, Autocomplete, Circle } from "@react-google-maps/api"
import markerIcon from '../images/299087_marker_map_icon.png'

const libraries = ["places"]
const mapContainerStyle = {
    width: "100%",
    height: "100%",
}

export default function CombinedModal({ onClose }) {
    const [floor, setFloor] = useState("")
    const [apartmentNo, setApartmentNo] = useState("")
    const [buildingNo, setBuildingNo] = useState("")
    const [addressNote, setAddressNote] = useState("") 
    const [warning, setWarning] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [coords, setCoords] = useState(() => {
        const savedLocation = JSON.parse(localStorage.getItem('userLocation'))
        return savedLocation ? { lat: savedLocation.lat, lng: savedLocation.lng } : { lat: 0, lng: 0 }
    })
    const [address, setAddress] = useState("")
    const [autocomplete, setAutocomplete] = useState(null)
    const [map, setMap] = useState(null)
    const [showLocationWarning, setShowLocationWarning] = useState(false)
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyD_HXna6RdG2QZbUgrCCmMGxnId91MT8Xo",
        libraries,
    })

    const restaurantLocation = { lat: 40.945333089189695, lng: 29.119637725783186 }
    const radiusInMeters = 1500

    useEffect(() => {
        const storedFloor = localStorage.getItem('floor')
        const storedApartmentNo = localStorage.getItem('apartmentNo')
        const storedBuildingNo = localStorage.getItem('buildingNo')

        if (storedFloor) setFloor(storedFloor)
        if (storedApartmentNo) setApartmentNo(storedApartmentNo)
        if (storedBuildingNo) setBuildingNo(storedBuildingNo)
    }, [])

    const handleLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    const { latitude, longitude } = position.coords
                    const initialCoords = { lat: latitude, lng: longitude }
                    setCoords(initialCoords)
                    fetchAddress(initialCoords)
                },
                function () {
                    setShowLocationWarning(true)
                }
            )
        }
    }, [])

    useEffect(() => {
        if (!coords.lat && !coords.lng) {
            handleLocation()
        } else {
            fetchAddress(coords)
        }
    }, [coords, handleLocation])

    function fetchAddress(location) {
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ location }, function (results, status) {
            if (status === "OK" && results[0]) {
                setAddress(results[0].formatted_address)
            }
        })
    }

    function onPlaceChanged() {
        if (autocomplete) {
            const place = autocomplete.getPlace()
            if (place.geometry) {
                const newCoords = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                }
                setCoords(newCoords)
                setAddress(place.formatted_address)
            }
        }
    }

    function onMapLoad(mapInstance) {
        setMap(mapInstance)
        fetchAddress(coords)
    }

    function onMapDragEnd() {
        if (map) {
            const center = map.getCenter()
            if (center) {
                const newCoords = {
                    lat: center.lat(),
                    lng: center.lng(),
                }
                fetchAddress(newCoords)
                setCoords(newCoords)
            }
        }
    }

    function updateCoordinates() {
        if (!address) {
            setErrorMessage("Adres barı boş bırakılamaz")
            setSuccessMessage("")
            setWarning("")
            return
        }
    
        if (!buildingNo || !apartmentNo) {
            setWarning("Lütfen Bina No ve Daire No alanlarını doldurunuz.")
            setSuccessMessage("")
            setErrorMessage("")
            return
        }
    
        try {
            localStorage.setItem('userLocation', JSON.stringify(coords))
            localStorage.setItem('userAddress', address)
            localStorage.setItem('addressNote', addressNote) 
            localStorage.setItem('floor', floor)
            localStorage.setItem('apartmentNo', apartmentNo)
            localStorage.setItem('buildingNo', buildingNo)
    
            setErrorMessage("")
            setWarning("")
            onClose() 
        } catch (error) {
            setErrorMessage("Bir hata oluştu.")
            setSuccessMessage("")
        }
    }    

    const handleBuildingNoChange = (e) => {
        const value = e.target.value
        if (value >= 0 || value === "") {
            setBuildingNo(value)  
        }
    }

    const handleApartmentNoChange = (e) => {
        const value = e.target.value
        if (value >= 0 || value === "") {
            setApartmentNo(value)  
        }
    }

    if (loadError) {
        return <div>Harita Yüklenirken Bir Problem Oluştu</div>
    }
    if (!isLoaded) {
        return <div>Harita Yükleniyor ...</div>
    }

    return (
        <div className="combined-modal">
            <div className="address-modal">
                <h2>Lütfen Adres Detaylarınızı Girin</h2>
                <div className="address-bars">
                    <div className="form-group">
                        <input
                            type="number"
                            min="0"
                            placeholder="Bina No."
                            value={buildingNo}
                            onChange={handleBuildingNoChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="number"
                            min="0"
                            placeholder="Daire No."
                            value={apartmentNo}
                            onChange={handleApartmentNoChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="number"
                            min="0"
                            placeholder="Kat No."
                            value={floor}
                            onChange={e => setFloor(e.target.value)}
                        />
                    </div>
                    <div className="form-group two-column">
                        <input
                            type="text"
                            placeholder="Adres Tarifi (Opsiyonel)"
                            value={addressNote}
                            onChange={e => setAddressNote(e.target.value)}
                        />
                    </div>
                </div>
                {warning && <p className="warning-text">{warning}</p>}
                {successMessage && <p className="success-text">{successMessage}</p>}
                {errorMessage && <p className="warning-text">{errorMessage}</p>} 
                <div className="order-button-wrapper">
                    <button className="order-button" onClick={updateCoordinates}>
                        <div className="order-button-text">
                            Adres Bilgilerini Kaydet <i className="fa-solid fa-map-location-dot"></i>
                        </div>
                    </button>
                    <button className="cancel-button" onClick={onClose}>İptal <i className="fa-solid fa-ban"></i></button>
                </div>
            </div>
            <div className="map-container">
                <div className="modal-close-container">
                    <i className="fa-solid fa-xmark modal-close"
                        onClick={onClose}
                        style={{
                            background: "none",
                            border: "none",
                            fontSize: "24px",
                            cursor: "pointer",
                            color: "red",
                        }}
                    ></i>
                </div>
                <Autocomplete onLoad={setAutocomplete} onPlaceChanged={onPlaceChanged}>
                    <input
                        type="text"
                        placeholder={address || "Search for a location..."}
                        value={address}
                        style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
                    />
                </Autocomplete>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={coords}
                    zoom={17}
                    onLoad={onMapLoad}
                    onDragEnd={onMapDragEnd}
                    options={{
                        gestureHandling: 'greedy',
                    }}
                >
                    <Circle
                        center={restaurantLocation}
                        radius={radiusInMeters}
                        options={{
                            strokeColor: "#FF0000",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "#FF0000",
                            fillOpacity: 0.10,
                        }}
                    />
                </GoogleMap>
                <div className="marker"
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -100%)",
                        width: "64px",
                        height: "64px",
                        background: `url(${markerIcon}) no-repeat center center`,
                        backgroundSize: "contain",
                        zIndex: 5,
                    }}
                />
            </div>
        </div>
    )
}
